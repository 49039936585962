import React from 'react'

import Link from 'next/link'

import { Container, Button } from 'reactstrap'
import useTranslation from '../hooks/UseTranslation'
import withLocale from '../hocs/WithLocale'

export async function getStaticProps(ctx) {
  const { locale, defaultLocale } = ctx
  return {
    props: {
      nav: {
        light: true,
        classes: 'shadow',
        color: 'white'
      },
      title: '404',
      locale,
      defaultLocale
    }
  }
}

const Page = () => {
  const { t } = useTranslation()
  return (
    <div className='mh-full-screen d-flex align-items-center dark-overlay'>
      <img
        src='/content/img/yoga-bg-main.jpg'
        alt='Not found'
        className='bg-image'
      />
      <Container className='text-white text-lg overlay-content py-6 py-lg-0'>
        <h1 className='display-3 font-weight-bold mb-5'>{t('404_title')}</h1>
        <p className='font-weight-light mb-5'>{t('404_content')}</p>
        <p className='mb-6'>
          <Link href='/'>
            <Button href='/' color='outline-light'>
              <i className='fa fa-home mr-2' />
              {t('404_button')}
            </Button>
          </Link>
        </p>
        <p className='h4 text-shadow'>{t('404_footer')}</p>
      </Container>
    </div>
  )
}

export default withLocale(Page)
